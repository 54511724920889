import React from "react";
import "./Expertises.css";

function Tab1 () {
    return (
        <div>
            <h1> Développer le bien être au  travail</h1>
            <p>Vous souhaitez attirer ou fidéliser les talents dans votre entreprise ou votre organisation ?
            La question du bien être au travail représente un enjeu central à l'atteinte de vos objectifs.<br/><br/>
            ACEROLA et ses consultants veillent aux dernières recherches et outils  permettant de structurer votre démarche.
            </p>
            <ul>
                <li>"Happy at work" questionnaire sur le bien être au travail de vos collaborateurs</li>
                <li>Fabrique Spinoza</li>
                <li>Aide au recrutement</li>
                <li> Organisation des flux d'informations</li>
                <li>Management bienveillant ET efficace</li>
                <li>Atelier "bien-être"</li>
                <li>GPEC</li>
                <li>Professionnalisation des entretiens professionnels</li>
            </ul>
        </div>
    );
}

function Tab2 () {
    return (
        <div>
            <h1>Prépa Mentale pour Sportifs</h1>
            <p>Vous êtes sportif ou entraineur, vous souhaitez franchir ou faire franchir un palier, vous avez pensé à la préparation physique, à la technique, à la stratégie de jeu,
                 mais avez vous pensé à la préparation mentale ?<br/>Avec l’aide d’un de ses coachs, à la suite d'un bilan initiale, ACEROLA vous établira un un programme « sur mesure » 
                 et vous accompagnera dans la mise en place de ce programme.<br/>Contacter nous pour faire le point sur les 8 outils de la préparation mentale.(Motivation, Concentration,
                 Stress, Routines, Imagerie Mentale …)<br/>ACEROLA est Partenaire du Montpellier beach volley (champion du monde des moins de 19 ans), et accompagne bénévolement le pôle 
                 espoir de beach volley de Montpellier.
            </p>
        </div>
    );
}

function Tab3 () {
    return (
        <div>
            <h1>Co-développement MENTOR®</h1>
            <p>« Le groupe de co-développement professionnel est une approche de développement pour des 
                personnes qui croient pouvoir apprendre les unes des autres afin d’améliorer leur pratique.<br/><br/>
                La réflexion effectuée, individuellement et en groupe, est favorisée par un exercice structuré 
                de consultation qui porte sur des problématiques vécues actuellement par les participants »
                ACEROLA est certifié en co-développement avec la méthodologie MENTOR®.
            </p>
        </div>
    );
}

function Tab4 () {
    return (
        <div>
            <h1>Qu'est-ce qu'une crise ?</h1>
            <p>Une organisation s'ajuste en permanence à son environnement tel un organisme vivant, 
                c'est le principe de l'homéostasie : régulation naturelle de l’organisme consistant 
                à maintenir constants les paramètres biologiques (température, composition du sang, ...) 
                face aux modifications du milieu extérieur.<br/><br/>
                Cette adaptation indolore et continu peut dans certaines situations s'avérer insuffisante au nouvel environnement.
                Nous ne parlons plus de changement mais de Crise. L'état précédent n'est plus accessible, adapté ou crédible à 
                l'atteinte de la performance attendue voir à la survie de l'organisation.<br/><br/>
                La Crise, qui signifie "décision" en Grec ou "Danger et opportunité" en japonais, n'est pas une situation 
                confortable pour les humains, les incertitudes, les renoncements et les craintes qu'elles génèrent 
                cristallisent les émotions et les tensions dans un groupe.<br/><br/>
                ACEROLA et ses coachs vous accompagnent dans ces périodes par des processus très encadrés et 
                professionnels au service de votre organisation, de ses dirigeants et de vos équipes.</p>
        </div>
    );
}

function Tab5 () {
    return (
        <div>
            <h1>Les Formations ACEROLA</h1>
            <p>ACEROLA est spécialisé dans le développement des potentiels humains en Management Motivationnel,
                 Développement Commercial, Accompagnement au Changement, Gestion de Conflit...<br/>
            Nos formations sont fortement influencées par les Neurosciences, profils de préférences cérébrales 
            de Ned HERRMANN (HBDI) et par l'Intelligence Emotionnelle  de Daniel GOLEMAN et l'outil du Dr Reuven Bar-On (EQ-i 2.0).<br/>
            <br/><u>Pédagogie de nos formations :</u></p>
            <ul>
           <li> Pour favoriser l’ancrage des connaissances, nous utilisons des techniques d’animations génératrices 
            d’émotions positives, vecteur indispensable à l’apprentissage.</li>
            <li>Pour garantir l’équilibre, le dynamisme, la motivation des participants, nous alternons des apports
             théoriques et des phases d'expérimentations pratiques sous forme d’ateliers collectifs</li>
             <li>Nous accordons une importance capitale à l’alternance des postures de l’animateur lors des formations.
             Postures de formateur lors des phases d’apports théoriques et posture de coach favorisant l’autonomie 
             et la prise de conscience individuelle et collective dans les phases d’atelier et de debriefing.</li>
             </ul>
           <p><u> Quelques exemples de formations :</u></p>
           <ul>
                <li>Gagner en Efficacité Relationnelle</li> 
                <li>L'equi-coaching au service de votre leadership</li> 
                <li>Le Théâtre d'impro...pour une meilleur prise de parole en public</li> 
                <li>Le Leader génération Z</li> 
                <li>Le leader slasher</li> 
                <li>Développer l'autonomie de son équipe par la créativité</li> 
                <li> Le coach AGILE</li> 
            </ul>
            
        </div>
    );
}

function Tab6 () {
    return (
        <div>
            <h1>Organiser un Audit</h1>
            <p>Vous envisagez des actions dans votre organisation, avoir une idée clair de l'état
                 de votre outil de production sur le plan humain, financier et/ou technique avant de définir le plan d'action ?<br/><br/>
                Nous mettons à votre disposition des outils de diagnostics performants  afin de vous produire un audit
                circonstancié de vos forces et points de progrès.</p>
                <ul>
                    <li>Diagnostic RSE</li>
                    <li>BOHP - Diagnostic RH (Certifié)</li>
                    <li>Audit comptable - financier</li>
                    <li>Audit global - organisationnel</li>
                </ul>
        </div>
    );
}

function Tab7 () {
    return (
        <div>
            <h1>Stratégie - Vision</h1>
            <p><b>Pourquoi une stratégie, une Vision ?</b><br/><br/>
            Que vous soyez une entreprise, une administration ou une association, 
            tout projet nait d'un idée qui se transforme en projet.<br/><br/>
            De ce projet il est important que naisse une Vision, c'est-à-dire une ambition, un idéal, 
            une perspective, qui répond à la question du Pourquoi?<br/><br/>
            Partant de cette Vision, on élabore ensuite une stratégie qui répondra à la question du Comment?
            ACEROLA vous accompagne dans la l'élaboration du plan d'action pour répondre  à  ces deux questions.</p>
        </div>
    );
}

function Tab8 () {
    return (
        <div>
            <h1>Accompagnements / Coaching</h1>
            <p>Le coaching est une méthode d’accompagnement s’appuyant sur les ressources internes de la personne
            ou d'un groupe dans l’objectif d’atteindre une autonomie de fonctionnement.
            <br/>Cet accompagnement s’inscrit dans la durée, sur plusieurs séances et plusieurs mois pour favoriser l’ancrage du changement.
            Par le questionnement bienveillant et responsable, le coach permet au(x) coaché(s) de dépasser ses (leurs)
            propres limites, issues d'un système de croyance souvent limitant ou de prendre le recul (position META)
            nécessaire afin d’observer la situation et les enjeux sous un autre angle et libérer les freins engendrés par la situation en présence.
            <br/>En résumé, un coach est un spécialiste de la question.
            Les coachs d'ACEROLA sont aguerris aux techniques de coaching par une longue expérience, 
            certifiés par des organismes reconnus et supervisés lors de leurs interventions.</p>
            <p><u>Modalités d’interventions :</u></p>
            <ul>
                <li>Coaching individuel</li>
                <li> Coaching d'équipe</li>
                <li>Coaching d'organisation</li>
                <li> Coaching d'associés</li>
            </ul>
        </div>
    );
}

function Tab9 () {
    return (
        <div>
                <h1>Cliquez sur le domaine qui vous intéresse !</h1>
                <p>Vous êtes dirigeant, associé ou manager ?
                    Votre environnement est en mouvement permanent et vous devez vous adapter ?
                    Vous aimez le dynamisme et la bonne humeur ?<br/><br/>

                    ACEROLA vous accompagne dans vos projets en développant vos potentiels.
                    Réel partenaire de votre performance humaine et financière, 
                    nous sommes spécialisés dans la motivation de l'Homme au travail.
                </p>
        </div>
    );
}

function TabExpertises ({NbClick, uptadeNClick}) {
    if (NbClick == 1) {
        return <Tab1 className="Tab1"/>;
    }
    if (NbClick == 2) {
        return <Tab2 className="Tab2"/>;
    }
    if (NbClick == 3) {
        return <Tab3 className="Tab3"/>;
    }
    if (NbClick == 4) {
        return <Tab4 className="Tab4"/>;
    }
    if (NbClick == 5) {
        return <Tab5 className="Tab5"/>;
    }
    if (NbClick == 6) {
        return <Tab6 className="Tab6"/>;
    }
    if (NbClick == 7) {
        return <Tab7 className="Tab7"/>;
    }
    if (NbClick == 8) {
        return <Tab8 className="Tab8"/>;
    }
    return <Tab9 className="Tab9"/>
};

export default TabExpertises;