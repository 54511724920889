import "./Equipe.css"
import React from "react";
import Nico from "../../assets/img/Nico.jpg";
import Claire from "../../assets/img/Claire.png";
import AnneLaure from "../../assets/img/AL.png";
import Cath from "../../assets/img/Cath.jpg";

function Equipe() {
  return (
    <div className="Equipe-Conteneur">
      <br/>
      <h1 className="text-equipe">L'équipe</h1>
      <div  className="equipe">

        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <h1>Nicolas DENIS</h1>
              <img src={Nico}   className="photo_equipe"/>
              <div className="equipe_para">
                <p>
                  Coach certifié HEC Paris Certifié en Intelligence Emotionnelle -Modéle EQ-i 2.0 Spécialisé en Management, 
                  en Leadership et en Stratégie.
                </p>
              </div>
            </div>
            <div className="flip-card-back">
              <div>
                <h2>Mes Boosters</h2>
                <p>	Le bon vin et le volley…toujours entre copains !</p>
              </div>
              <div>
                <h2>Présentation</h2>
                <p>
                Après une expérience d’une quinzaine d’année à la tête de PME, 
                j’ai fait le choix de transmettre mes connaissances et mon expérience en Management, 
                gestion-comptabilité et leadership aux entreprises. Passionné par la place de « l’Homme au travail » et les enjeux du collectif.
                </p>
              </div>
            </div>
          </div>
        </div>
        

        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <h1>Anne-Laure Germond</h1>
              <img src={AnneLaure} className="photo_equipe"/>
              <div className="equipe_para">
                <p>
                Consultante et formatrice en communication<br/>
                Praticienne en énergétique chinoise – Yoga et Pilates
                </p>
              </div>
            </div>
            <div className="flip-card-back">
              <div>
                <h2>Mes Boosters</h2>
                <p>La danse, la musique, le yoga, le partage et la découverte de nouveaux horizons.</p>
              </div>
              <div>
                <h2>Présentation</h2>
                <p>
                Après plus de 20 ans en gestion de politique de communication BtoB et BtoC, je crée EssenCiel Zen. 
                Praticienne en énergétique chinoise et Yoga-Pilates, j’allie aujourd’hui ces deux passions lors de mes 
                interventions à destination des équipes et des dirigeants (ateliers collectifs et séances individuelles) 
                axées sur la communication bidirectionnelle entre l’esprit et le corps par le mouvement, la respiration et le toucher.
                 <br/><i>«Le mouvement est le principe de toute vie»</i> Léonard de Vinci.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <h1>Catherine CAMARA</h1>
              <img src={Cath}   className="photo_equipe"/>
              <div className="equipe_para">
                <p>
                  Formatrice et consultante, communication et management responsables
                  DU Blended Learning Master Communication
                </p>
              </div>
            </div>
            <div className="flip-card-back">
              <div>
                <h2>Mes Boosters</h2>
                <p>	Voyager, la Soul music, Frida Kahlo, Bouquiner et Rire !</p>
              </div>
              <div>
                <h2>Présentation</h2>
                <p>
                 Après un parcours de plus de 20 ans dans la communication, 
                 je crée en 2013 Le goût de l’autre où je conçois et anime des ateliers de formation dédiés au management responsable :
                 Gestion du temps, bien-être au travail,
                 cohésion et motivation d\'équipe, gestion des talents.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <h1>Claire LE GALL</h1>
              <img src={Claire}   className="photo_equipe"/>
              <div className="equipe_para">
                <p>
                  Equi coaching <br/>Guide de tourisme équestre
                </p>
              </div>
            </div>
            <div className="flip-card-back">
              <div>
                <h2>Mes Boosters</h2>
                <p>	
                  Les sports de pleine nature, les plaisirs de la vie simple, la gastronomie, le vin.
                </p>
              </div>
              <div>
                <h2>Présentation</h2>
                <p>
                  Après avoir eu un parcours très varié 
                  qui m’a permis d’acquérir une expérience dans plusieurs domaines et dans tous types de structures :
                  transport et logistique, finance, gestion relationnel client, 
                  animation et coordination d’un réseau de professionnels de sports de pleine nature
                  et à ce jour gérante du Domaine de St Germain
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
export default Equipe;