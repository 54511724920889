import "./Outils.css"
import logo from "../../assets/img/Herrmann.png";
import logo_pdf from "../../assets/img/pdf.png";


function Herrmann(props) {
  if (!props.warn) {
  return (
    <div>
        <img src={logo} className="logo" />
        <div className="herrmann-text">
          <p>Ned Herrmann est à l'origine de la pensée à Cerveau Total® dans le domaine des affaires et de l'éducation.</p>
          <p>Considéré aux USA comme le père de la Technologie des Préférences Cérébrales®, il a utilisé des modèles du fonctionnement du cerveau pour établir un modèle du fonctionnement de l'esprit.</p>
          <p>En 1995, la parution de son livre "The Whole Brain Business Book" a crée une nouvelle référence dans la recherche de styles de pensée, les domaines stratégiques comme le leadership, la productivité, les ventes et le travail d'équipe.</p>
          <p>Chacun de nous possède un ensemble naturel de styles de pensée qui influence la manière dont nous intéragissons avec les autres, résolvons des problèmes et prenons des décisions. Bien qu'il n'existe pas une meilleure manière de penser, ces schémas établis de longue date nous empêchent souvent d'atteindre notre plus grand potentiel.</p>
          <p><b>L'approche des préférences cérébrales de Ned Herrmann</b></p>
          <p>S'appuyant sur les recherches des neuro-biologistes Roger Sperry et Paul McLean sur le fonctionnement cérébral, le chercheur américain Ned Herrmann a mis en relation latéralisation cérébrale et traitement de l'information. Il a démontré que les individus "sélectionnent" les stimuli qui déclenchent leurs actions en fonction de leurs "préférences cérébrales".</p>
          <p>Partant de cette approche, Ned Herrmann a élaboré le questionnaire HBDI® (Herrmann Brain Dominance Instrument®), qui, après traitement, produit le "Profil des Préférences Cérébrales®" d'une personne. Ainsi il devient possible de comprendre comment nos choix professionnels, notre manière de travailler, d'apprendre, de manager et de communiquer sont influencés par nos préférences cérébrales.</p>
          <p>Ned Herrmann a élaboré une grille qui fonctionne comme une carte simplifiée de l'esprit humain.</p>
          <div className="pdf">
            <a href="https://cdn.website-editor.net/66a6a93fa4ad4a1ca1d8220295f91c76/files/uploaded/HBDI%2520Profil.jpg" target="_blank">
                <img src={logo_pdf} className="logo_pdf"/>
			      </a>
          </div>
        </div>

    </div>
  )}

  return (
    <div>
      <img src={logo} className="logo" />
    </div>
  );
}

export default Herrmann