import React from 'react'
import "./EquiCoaching.css"
import EquiCoaching1 from "../../assets/img/EquiCoaching1.jpeg";
import EquiCoaching2 from "../../assets/img/EquiCoaching2.jpg";
import EquiCoaching3 from "../../assets/img/EquiCoaching3.jpg";
import EquiCoaching4 from "../../assets/img/EquiCoaching4.mp4";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function EquiVue(props) {
  if (!props.warn) {
    return (
      <div className="img-conteneur-equi">
        <div className="image-container-2">
          <Carousel autoPlay={false} showStatus={false} showThumbs={false} infiniteLoop={true} interval={3000} transitionTime={900}>
            <div>
              <img src={EquiCoaching1} alt="Image1" />
            </div>
            <div>
              <img src={EquiCoaching2} alt="Image2" />
            </div>
            <div>
              <img src={EquiCoaching3} alt="Image3" />
            </div>
            <div>
              <video width={"100%"} height={"100%"} controls muted>
                <source src={EquiCoaching4} type="video/mp4" />
              </video>
            </div>
          </Carousel>
        </div>
        <div className='text-container-2'>
          <div className='desc-equi'>
            <div className='text-center'><b>Qu’est-ce que l’équi-coaching ou expérimentation avec le cheval ?</b></div>
            <br />
            C’est une aventure durant laquelle nous allons pouvoir observer vos comportements en utilisant le cheval comme interlocuteur.
            Cette aventure a comme objectif individuel la prise de conscience puis le renforcement votre posture au service de votre autorité naturelle ou consentie, appelée aussi leadership.
            Cette une aventure qui peut aussi être collective, elle permettra de mettre au jour des comportements tant sur « moi dans le groupe » que sur « comment fonctionne ce groupe au travail ou lors de cet atelier ? ».
            <br /><br />
            L’équi-coaching est avant tout une expérience émotionnelle durant laquelle le cheval fait « miroir » à notre comportement quand nous sommes en situation d’exercer une autorité vis à vis de lui, c’est-à-dire en position de « leader influent ».
            Pourquoi le cheval ? Le cheval étant un proie dans la nature, il a développé une hypersensibilité aux stimulus extérieurs, il va donc réagir immédiatement à nos sollicitations et tentatives comme un miroir.
            Nous utilisons donc ces interactions avec l’objectif principale de favoriser l’autonomie et la prise de responsabilités.
            <br /><br />
            L’equi-coaching met la focale sur vos pratiques, comportementales et émotionnelles, notamment sur les aspects suivants :
            <div>
              <ul>
                <li>Être au clair sur ses but et son intention,</li>
                <li>Mettre au jour certains parasites émotionnels,</li>
                <li>Etre aligné « intérieurement » : Mon idées, mes envies sont elles en cohérence avec mes expressions verbales et corporels.</li>
                <li>Ma relation à la confiance : Lorsque les événements m’échappent ai-je besoin de contrôler, de laisser aller, de ...</li>
              </ul>
            </div>
            Exemples d’une séance d’équi-coaching (individuellement ou collectivement) :
            <br />
            Ma communication avec les humains.<br />
            Comment sortir d’une situation inconfortable ?<br />
            Mise en situations professionnelles, mon rôle de manager, le contexte de l’entreprise, mon chef, mes collègues...<br />
            Comment fonctionne notre CODIR et quels enseignements pouvons nous en tirer ?<br />
            Face à une situation bloquée, quelle cohérence y a t’il entre les comportements et les intentions ?<br />
            Comment renforcer la cohésion de l’équipe ?
            <br /><br />
            <div className='text-center'><b>Vous souhaitez une expérience, marquante, inoubliable, émotionnelle et transformante, n’hésitez pas appeler nous !!!</b></div>
            <br />
            <i>PS : L’ensemble des situations sont animée avec le soucis permanent de garantir votre sécurité.</i>
          </div>
        </div>
      </div>
    )
  }

  return (null);
}

class EquiCoaching extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWarning: true,
      title: "EquiCoaching"
    }
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }


  handleToggleClick() {
    this.setState(prevState => ({
      showWarning: !prevState.showWarning,
      title: prevState.title === "EquiCoaching" ? "EquiCoaching" : "EquiCoaching"
    }));
  }
  render() {
    return (
      <div className={this.state.showWarning ? "Equi" : "Equi-active"}>
        <div className="title-equi">
          <h1>{this.state.title}</h1>
          <br />
          <button onClick={this.handleToggleClick}><h2>Description</h2></button>
        </div>
        <EquiVue warn={this.state.showWarning} />
      </div>
    )
  }
}

export default EquiCoaching;
