import React from 'react'
import "./CarousselClients.css"
import Logo1 from "../../assets/img/Clients/AccorHotels.png";
import Logo2 from "../../assets/img/Clients/Conserves_France.jpeg";
import Logo3 from "../../assets/img/Clients/Just_happiness.png";
import Logo4 from "../../assets/img/Clients/Logo-Eoden.png";
import Logo5 from "../../assets/img/Clients/Thalazur.jpeg";
import Logo6 from "../../assets/img/Clients/logo_ethicom.png";
import Logo7 from "../../assets/img/Clients/Brl.png";
import Logo8 from "../../assets/img/Clients/Formaxiom.png";
import Logo9 from "../../assets/img/Clients/LRA.png";
import Logo10 from "../../assets/img/Clients/MBS.jpeg";
import Logo11 from "../../assets/img/Clients/logo-difference.png";
import Logo12 from "../../assets/img/Clients/logo_nicollin.png";
import Logo13 from "../../assets/img/Clients/CCI_NIMES.png";
import Logo14 from "../../assets/img/Clients/Herault_tourisme.gif";
import Logo15 from "../../assets/img/Clients/Lidl-Logo.png";
import Logo16 from "../../assets/img/Clients/Mercure_Antigone.png";
import Logo17 from "../../assets/img/Clients/logo-le-boulonnais.png";
import Logo18 from "../../assets/img/Clients/logoccisudform.png";
import Logo19 from "../../assets/img/Clients/Chambre_d-agri_PACA.png";
import Logo20 from "../../assets/img/Clients/Ibis_Hotel_Logo_2016.png";
import Logo21 from "../../assets/img/Clients/Logo-CNFPT.jpeg";
import Logo22 from "../../assets/img/Clients/PW_Consultants.png";
import Logo23 from "../../assets/img/Clients/logo-pullman.png";


function CarousselVue(props) {
  if (!props.warn) {
    return (
      <div className="img-conteneur">
            <div className="ligne-1">
              <div className="client-element-1"><img src={Logo1} alt=""/></div>
              <div className="client-element-2"><img src={Logo2} alt=""/></div>
              <div className="client-element-3"><img src={Logo3} alt=""/></div>
              <div className="client-element-4"><img src={Logo4} alt=""/></div>
              <div className="client-element-5"><img src={Logo5} alt=""/></div>
              <div className="client-element-6"><img src={Logo6} alt=""/></div>
            </div>
            <div className="ligne-1">
              <div className="client-element-7"><img src={Logo7} alt=""/></div>
              <div className="client-element-8"><img src={Logo8} alt=""/></div>
              <div className="client-element-9"><img src={Logo9} alt=""/></div>
              <div className="client-element-10"><img src={Logo10} alt=""/></div>
              <div className="client-element-11"><img src={Logo11} alt=""/></div>
              <div className="client-element-12"><img src={Logo12} alt=""/></div>
            </div>
            <div className="ligne-1">
              <div className="client-element-13"><img src={Logo13} alt=""/></div>
              <div className="client-element-14"><img src={Logo14} alt=""/></div>
              <div className="client-element-15"><img src={Logo15} alt=""/></div>
              <div className="client-element-16"><img src={Logo16} alt=""/></div>
              <div className="client-element-17"><img src={Logo17} alt=""/></div>
              <div className="client-element-18"><img src={Logo18} alt=""/></div>
            </div>
            <div className="ligne-1">
              <div className="client-element-19"><img src={Logo19} alt=""/></div>
              <div className="client-element-20"><img src={Logo20} alt=""/></div>
              <div className="client-element-21"><img src={Logo21} alt=""/></div>
              <div className="client-element-22"><img src={Logo22} alt=""/></div>
              <div className="client-element-23"><img src={Logo23} alt=""/></div>
            </div>
          </div>
    )}

    return (null);
}

class CarousselClients extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {showWarning: true}
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState(prevState => ({
      showWarning: !prevState.showWarning
    }));
  }
  render () {
    return (
        <div className={this.state.showWarning ? "Client" : "Client-active"}>
          <div className="title-clients">
            <h1>Nos clients</h1>
            <br/>
            <button onClick={this.handleToggleClick}><h2>Ils nous font confiance</h2></button>
          </div>
          <CarousselVue warn={this.state.showWarning}/>
        </div>
    )
  }
}

export default CarousselClients;
