import "./Outils.css"
import Eqi from './Eqi'
import React from 'react';
import Herrmann from "./Herrmann";

class PageHermann extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showWarning: true}
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState(prevState => ({
      showWarning: !prevState.showWarning
    }));
  }
  
  render() {
    return (
      <div className="herrmann">
        <Herrmann warn={this.state.showWarning} />
        <button onClick={this.handleToggleClick} className="outils-button" >
          {this.state.showWarning ? 'Voir plus ...' : 'Voir moins ...'}
        </button>
      </div>
    );
  }
}

class PageEqi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showWarning: true}
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState(prevState => ({
      showWarning: !prevState.showWarning
    }));
  }
  
  render() {
    return (
      <div className="eqi">
        <Eqi warn={this.state.showWarning} />
        <button onClick={this.handleToggleClick} className="outils-button" >
          {this.state.showWarning ? 'Voir plus ...' : 'Voir moins ...'}
        </button>
      </div>
    );
  }
}


function Outils() {
  return (
    <div className="outils">
      <div  className="IntroOutils">
        <h2>Nos Outils</h2>
        <p>Animés par un souci de <b>performance</b> dans nos actions, nous avons fait le choix de nous spécialiser et nous <b>certifier</b> dans l'utilisation de <b>deux outils de diagnostics</b>.</p>
        <p>Ces outils, plus qu'une fin en soi, servent de <b>supports à nos interventions</b> pour <b>favoriser la prise de conscience</b> et la <b>mise en action</b> des individus ou organisation que  nous accompagnons. </p>
        <br/>
        <br/>
        <br/>
      </div>
      <div className="detail_outils">
        <PageEqi />
        <PageHermann />
      </div>
    </div>
  );
}

export default Outils;