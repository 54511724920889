import React from 'react'
import Logo from "../../assets/img/LogoBlancBaseline.png"
import './Welcome.css'

const Welcome = ({element}) => {
    return (
            <main>
                <section className='welcome'>
                    <div ref={element} className="welcome-details">
                        <img src={Logo} alt='logo' className='welcome--logo' />
                        <p>Conseil - Coaching - Consultant <br/>Management et Stratégie d'entreprise</p>
                    </div>
                    <div>
                        <button className='welcome__cta-primary'>
                            <a href="#Contacts">Nous Contacter</a>
                        </button>
                    </div>
                </section>
            </main>
    )
}

export default Welcome