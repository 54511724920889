import React from "react";
import "./CarousselPartners.css";
// import Logo from "../../assets/img/Logo.png"
import Logo_ethicom from "../../assets/img/Partenaires/Logo_ethicom.jpg"
import Logo_horse_aventure from "../../assets/img/Partenaires/logo_horse_aventure_2.png"
import Logo_formaxiom from "../../assets/img/Partenaires/logo_formaxiom.jpg"
import Logo_anatol from "../../assets/img/Partenaires/logo_anatol.png"
import Logo_mister_aventure from "../../assets/img/Partenaires/logo_mister_aventure.png"
import Logo_domaine_st_germain from "../../assets/img/Partenaires/logo_domaine_st_germain_2.jpg"
import Logo_cocotte_minute from "../../assets/img/Partenaires/logo_cocotte_minute.png"
import Logo_axiome from "../../assets/img/Partenaires/logo_axiome.png"
import Logo_occitanie_mediterranee from "../../assets/img/Partenaires/logo_occitanie_mediterranee.png"
import Marquee from "react-easy-marquee";

const CarousselPartners = () => {
return (
    <div>
        <h1 className="caroussel-title" >Nos partenaires</h1>
        <Marquee duration={25000} height="300px">
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_ethicom} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_horse_aventure} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_formaxiom} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_anatol} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_mister_aventure} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_domaine_st_germain} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_axiome} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_cocotte_minute} alt="Logo"/></a>
            </div>
            <div className="caroussel-marque" >
            <a href="#" ><img className="caroussel-img" src={Logo_occitanie_mediterranee} alt="Logo"/></a>
            </div>
        </Marquee>
    </div>
)};

export default CarousselPartners;