import "./Outils.css"
import logo from "../../assets/img/Eqi.png";
import logo_pdf from "../../assets/img/pdf.png";

function Equi(props) {
  if (!props.warn) {
  return (
    <div>
      <img src={logo} className="logo" />
      <div className="eqi-text">
        <p>L'intelligence émotionnelle connaît à l’heure actuelle un engouement sans précédent ; tous les professionnels s'accordent à le dire : le savoir-faire (diplômes, compétences techniques, expérience,…) n’est plus l’élément clef de la performance ; le savoir-être constitue dorénavant la valeur ajoutée d’un être humain. </p>
			  <p>Accompagner le développement de l'intelligence émotionnelle en utilisant l'outil de diagnostic le plus reconnu dans ce domaine vous permettra de devenir une référence et un expert en intelligence émotionnelle.</p>
        <p><u>La définition de l'intelligence émotionnelle que nous adoptons : </u></p>
			  <p>L'intelligence émotionnelle est un ensemble d'aptitudes émotionnelles et sociales influençant la façon dont nous :</p>
			  <ul>
	        <li>Nous nous percevons et nous nous exprimons (c'est-à-dire, à faire l'analyse de soi, à se connaître soi-même et à formuler ses pensées)</li>
			    <li>Développons et maintenons des relations sociales (sur les plans professionnel et personnel)</li>
	        <li>Agissons dans la difficulté (ce qui est important, car chacun perçoit les difficultés de manière différente)</li>
			    <li>Utilisons les informations émotionnelles de façon efficace et appropriée (exploitons-nous efficacement nos émotions dans notre quotidien ?)</li>
	      </ul>
		    <p>L'intelligence émotionnelle ne désigne pas la mesure de l'émotivité. Elle est plutôt un indicateur de notre efficacité au quotidien, quel que soit le rôle que nous jouons. Elle désigne notre aptitude à exploiter nos émotions de façon saine et constructive afin de réussir sur les plans professionnel et personnel.</p>
        <p>Ned Herrmann a élaboré une grille qui fonctionne comme une carte simplifiée de l'esprit humain.</p>
        <div className="pdf">
          <a href="https://cdn.website-editor.net/66a6a93fa4ad4a1ca1d8220295f91c76/files/uploaded/roue%2520%252B%2520texte%2520en%2520franc%25CC%25A7ais.jpg" target="_blank">
            <img src={logo_pdf} className="logo_pdf"/>
          </a>
        </div>
      </div>
    </div>
  )}

  return (
    <div >
      <img src={logo} className="logo" />
    </div>
  );
}

export default Equi