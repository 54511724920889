import React, { useState } from "react";
import "./Expertises.css";
import MapExpertises from "./MapExpertises";
import TabExpertises from "./TabExpertises";
import ListExpertises from "./ListExpertises";


const Expertises = () => {
  const [NbClick, uptadeNbClick] = useState([])
return (
    <div>
        <div className="expertises-conteneur">
            <div className="expertises-element-1">
                <MapExpertises NbClick={NbClick} uptadeNbClick={uptadeNbClick}/>
            </div>
            <div className="expertises-element-2">
                <TabExpertises NbClick={NbClick} uptadeNbClick={uptadeNbClick}/>
            </div>
        </div>
        <div className="expertises-element-3">
            <ListExpertises NbClick={NbClick} uptadeNbClick={uptadeNbClick}/>
        </div>
    </div>
)};
export default Expertises;