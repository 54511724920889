import React from "react";
import "./NavBar.css";
import LogoB from "../../assets/img/LogoBlancNoWord.png";
import LogoV from "../../assets/img/LogoVioletNoWord.png";
import { useState } from "react";

const Navbar = ({ sticky }) => {
    const [isActive, setIsActive] = useState(false);

    function mobileMenus() {
        if (!isActive)
            setIsActive(true);
        else
            setIsActive(false);
    }
    
    function closeMenus() {
        setIsActive(false);
    }

return (
    <nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
        
        <div className="navbar--logo-holder">
            {sticky ? <img src={LogoB} alt="logo" className="navbar--logo" /> : <img src={LogoV} alt="logo" className="navbar--logo" />}
            <h1> acerola</h1>
        </div>
        <ul className={isActive ? "navbar--link active" : "navbar--link"}>
            <li className="navbar--link-item"><a href="#Accueil" onClick={closeMenus}>Accueil</a></li>
            <li className="navbar--link-item"><a href="#Expertises" onClick={closeMenus}>Expertises</a></li>
            <li className="navbar--link-item"><a href="#Equipe" onClick={closeMenus}>L'équipe</a></li>
            <li className="navbar--link-item"><a href="#Outils" onClick={closeMenus}>Outils</a></li>
            <li className="navbar--link-item"><a href="#Contacts" onClick={closeMenus}>Contacts</a></li>
        </ul>
        
        <div className={isActive ? "hamburger active" : "hamburger"} onClick={mobileMenus}>
            <span className={sticky ? "bar bar-sticky" : "bar"}></span>
            <span className={sticky ? "bar bar-sticky" : "bar"}></span>
            <span className={sticky ? "bar bar-sticky" : "bar"}></span>
        </div>
       
    </nav>
)};

export default Navbar;