import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Temoignage from './Temoignage'
import { TemoignageList } from './Data'
 
const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false
}
 
const Slideshow = () => {
    return (
      <div className="Temoignage-conteneur">
        <Slide {...properties}>
          {TemoignageList.map(({ index, nom, temoignage, entreprise})=> 
          <Temoignage key={index}
            index = {index}
            nom = {nom}
            temoignage = {temoignage}
            entreprise = {entreprise}
        />
        )
        }
      </Slide>
      </div>
    )
}

export default Slideshow;