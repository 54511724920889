export const TemoignageList = [
	{	
		index: 1,
		nom: 'Isabelle Marin et son équipe.',
		temoignage: '" Nicolas a un réel sens du contact et il utilise des outils très pertinents pour déterminer la personnalité de chacun. Il est très agréable d’échanger avec lui et ses conseils sont pertinents et facilement applicables dans la vie professionnelle et personnelle.  La formation sur l’intelligence émotionnelle a été très appréciée par l’ensemble des chargées de communication du groupe Axiome Associés. Notamment, grâce aux activités pratiques et aux clés données par Nicolas pour mieux comprendre et mieux gérer certains aspects de sa personnalité.Isabelle Marin et son équipe. "',
		entreprise: 'Axiome',
	},
	{	
		index: 2,
		nom: 'Olivier SAVEY ',
		temoignage: '" J\'ai travaillé avec Nicolas sur la mise en oeuvre d\'une formation en management pour des dirigeants et la collaboration s\'est très bien passée. Dynamique et force de proposition, Nicolas centre ses interventions sur les pratiques d\'entreprises et la résolution de problématique par le collectif. A une prochaine collaboration ! "',
		entreprise: 'ANATOL Formation ',
	},
	{	
		index: 3,
		nom: 'Pascal SCHIMDT',
		temoignage: '" Nous avons eu recours aux services de Nicolas Denis dans le cadre d’un accompagnement managérial à la production en « mode projet » .Son expérience, son savoir-faire et son dynamisme nous a fait progresser, amenant véritablement les éléments d’un culture nouvelle encore référente pour notre l’équipe aujourd’hui… "',
		entreprise: 'Directeur Herault Tourisme ',
	},
	{	
		index: 4,
		nom: 'Nicolas DUBOC & Julien ROSET',
		temoignage: '" Nicolas a su nous donner les clés et les méthodes pour permettre de définir une vision d’entreprise et un système de management qui nous sont propres. Il nous fait réfléchir, nous remet en question parfois, nous aide à nous projeter, toujours dans le but de nous aider à prendre des décisions qui nous ressemblent. Il s\'est adapté à nous et il est devenu l’un de nos plus proches partenaires. "',
		entreprise: 'Just Happiness ',
	},
	{
		index: 5,
		nom: 'Bert CANDAELE',
		temoignage: '"Acerola a su nous accompagner aussi bien individuellement que collectivement. J\'ai trouvé la démarche très humaine, elle nous a permis d\'améliorer le fonctionnement de l\'association et nous a aidé à surmonter nos difficultés. Les outils de travail proposés sont très pertinents, nous ne les oublierons pas! "',
		entreprise: 'CRIEPPAM',
	}
]