import React from "react";
import "./Infos.css";
import Nico from "../../assets/img/Nico.jpg"
import Background from "../../assets/img/LogoBlancNoWord.png"

const Infos = () => {
return (
    <div className="infos-conteneur">
        <div className="infos-element-1" >
            <img src={Nico} alt="" className="infos-img"/>
        </div>
        <div className="infos-element-2">
            <h1 >Pourquoi Acerola ?</h1>
            <p>
            “ J’ai créé ACEROLA à partir d’une envie de transmettre, avec le désir d’apporter ma contribution au développement des entreprises en alliant efficacité et bienveillance.
            A l’heure du choix du nom du cabinet, ACEROLA s’est imposé comme une évidence. Le potentiel vitaminé de cette magnifique baie des Antilles représente à merveille mon désir de proposer des interventions dynamiques et efficaces tant en coaching qu’en formation.
            En lien avec mes expériences de dirigeants et en conformité avec mes convictions sur la richesse du groupe, je me définis comme un homme de collectif. C’est ainsi qu’au gré de mes rencontres je propose à des consultants expérimentés et complémentaires, motivés par les mêmes valeurs, de rejoindre l’aventure du développement des potentiels de l'Homme au service de votre organisation.”
            </p>
            <img src={Background} alt=""/>
        </div>
    </div>
)};


export default Infos;