import React from "react";
import "./Temoignage.css"

function Temoignage({ index, nom, temoignage, entreprise}) {
return(
  <div className="Temoignage">

    <h1>{nom}</h1>
    <p>{temoignage}</p>
    <p className="entreprise">{entreprise}</p>

  </div>
    
  );
}
export default Temoignage