import React, { useState, useRef } from "react";
import "./ContactForm.css"
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    handleSubmit();
    emailjs.sendForm('service_t17r69v', 'template_p8xlnko', form.current, 'b56RtPPwKfRRqOw0p')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  if (submitted) {
    return (
      <>
        <h2 className="thanks">Merci !</h2>
        <div className="thanks">Nous vous contacterons bientôt.</div>
      </>
    );
  }
  else {
    return (
      <form
      id="form"
      ref={form} 
      onSubmit={sendEmail}
      >
              <div>
                <input type="text" placeholder="Nom" name="nom" required />
              </div>
              <br />
              <div>
                <input type="text" placeholder="Prénom" name="prenom" required />
              </div>
              <br />
              <div>
                <input type="email" placeholder="Email" name="mail" required />
              </div>
              <br />
              <div>
                <textarea placeholder="Message" name="message" required />
              </div>
              <div>
                <button type="submit"> Send</button>
              </div>
      </form>
    );
  }
};

export default ContactForm;