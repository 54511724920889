import React from "react";
import "./Expertises.css";
import Logo from "../../assets/img/AcerolaRondFinal.png";
import "../../plugin/jquery.rwdImageMaps"
import $ from "jquery"

const MapExpertises = ({NbClick, uptadeNbClick}) => {
    $(document).on("load", function(e){
        $('img[usemap]').rwdImageMaps();
    });
    $(".img-1").off("click").on("click", function(e){
        e.preventDefault()
        uptadeNbClick([NbClick = 1])
    });
    $(".img-2").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 2]);
    });
    $(".img-3").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 3]);
    });
    $(".img-4").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 4]);
    });
    $(".img-5").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 5]);
    });
    $(".img-6").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 6]);
    });
    $(".img-7").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 7]);
    });
    $(".img-8").off("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 8]);
    });
return (
    <div>
        <map name="circle-expertises" className="map-lien-img" width="530px" height="530px">
            <area shape="rect" coords="81,70,166,141" alt="1" href="" className="img-1 select" />
            <area shape="rect" coords="2,243,114,297" alt="2" href="" className="img-2 select"/>
            <area shape="rect" coords="57,380,166,486" alt="3" href="" className="img-3 select"/>
            <area shape="rect" coords="243,496,324,559" alt="4" href="" className="img-4 select"/>
            <area shape="rect" coords="419,424,499,488" alt="5" href="" className="img-5 select"/>
            <area shape="rect" coords="506,261,584,334" alt="6" href="" className="img-6 select"/>
            <area shape="rect" coords="444,72,515,149" alt="7" href="" className="img-7 select"/>
            <area shape="rect" coords="257,3,342,64" alt="8" href="" className="img-8 select"/>
        </map>
        <img src={Logo} useMap="#circle-expertises" alt="Cercle Expertises" className="expertises-img"/>
    </div>
)};


export default MapExpertises;