import Maps from "../../assets/img/Maps2.png"
import ContactForm from "../ContactForm/ContactForm";
import "./Footer.css"

const Footer = () => {
  return (
    <div>
      <div className="conteneur">
        <div className="element-1">
          <h1>Nous contacter :</h1>
          <p>ACEROLA Siège - Nicolas DENIS <br/><br/>
            06 15 38 21 53<br/><br/>
            <a href="mailto:n.denis@acerola4c.com" className="link">n.denis@acerola4c.com</a><br/><br/>
            9 bis Chemin de L'Espandidou<br/><br/>
            34800 Aspiran
          </p>
        </div>
        <div className="element-2">
          <ContactForm />
        </div>
        <div className="element-3">
          <img src={Maps} alt="Maps" className="maps-img"/>
        </div>
      </div>
      <footer className="foot">
        <p>Ⓒ Copyright  |  All rights reserved  |  Webdesigner <a href="https://www.linkedin.com/in/laurine-denis-59a9a1a8">Laurine Denis</a></p>
      </footer>
    </div>
  );
};

export default Footer;