import React from "react";
import "./Expertises.css";
import "../../plugin/jquery.rwdImageMaps"
import $ from "jquery"

const ListExpertises = ({NbClick, uptadeNbClick}) => {
    $(".link-1").unbind("click").on("click", function(e){
        e.preventDefault()
        uptadeNbClick([NbClick = 1])
    });
    $(".link-2").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 2]);
    });
    $(".link-3").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 3]);
    });
    $(".link-4").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 4]);
    });
    $(".link-5").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 5]);
    });
    $(".link-6").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 6]);
    });
    $(".link-7").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 7]);
    });
    $(".link-8").unbind("click").on("click", function(e){
        e.preventDefault();
        uptadeNbClick([NbClick = 8]);
    });
return (
    <div name="list-expertises" className="list-expertises">
        <a alt="1" className="link-1 list-link"><b>RH & QVT</b></a>
        <b className="sep-link">|</b>
        <a alt="2" className="link-2 list-link"><b>SPORTIFS</b></a>
        <b className="sep-link">|</b>
        <a alt="3" className="link-3 list-link"><b>CO - DEVELOPPEMENT</b></a>
        <b className="sep-link">|</b>
        <a alt="4" className="link-4 list-link"><b>GESTION DE CRISE</b></a>
        <b className="sep-link">|</b>
        <a alt="5" className="link-5 list-link"><b>FORMATIONS</b></a>
        <b className="sep-link">|</b>
        <a alt="6" className="link-6 list-link"><b>AUDIT</b></a>
        <b className="sep-link">|</b>
        <a alt="7" className="link-7 list-link"><b>STRATEGIE - VISION</b></a>
        <b className="sep-link">|</b>
        <a alt="8" className="link-8 list-link"><b>COACHING</b></a>
    </div>
)};


export default ListExpertises;