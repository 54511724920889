import './App.css';
import useSticky from './NavBar/useSticky.js'
import Welcome from './NavBar/Welcome'
import NavBar from './NavBar/NavBar'
import Footer from './Footer/Footer';
import CarousselPartners from './Partenaires/CarousselPartners';
import Infos from './Infos/Infos';
import Equipe  from './Equipe/Equipe'
import Outils  from './Outils/Outils'
import Expertises from './Expertises/Expertises';
import Slideshow from './Temoignage/Slide'
import CarousselClients from './Clients/CarousselClients';
import EquiCoaching from './Equicoaching/EquiCoaching';

function App() {
  const { isSticky, element } = useSticky()
  return (
    <>
      <NavBar sticky={isSticky} />
      <span id="Accueil"></span>
      <Welcome element={element} />

      <span className="anchor_expertise" id="Expertises"></span>
      <Expertises />
     
      <Infos />
  
      <Slideshow /> 
 
      <span className="anchor_Equipe"  id="Equipe"></span>
      <Equipe />
   
      <CarousselPartners />
      <div className='boxes'>
        <CarousselClients />
        <EquiCoaching />
      </div>

      <span className="anchor_Outils"  id="Outils"></span>
      <Outils />
    
      <span className="anchor_Footer"  id="Contacts"></span>
      <Footer />
    </>
  );
}

export default App;
